import { render, staticRenderFns } from "./ProjectInfo.vue?vue&type=template&id=4b7cb671&scoped=true"
import script from "./ProjectInfo.vue?vue&type=script&lang=js"
export * from "./ProjectInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b7cb671",
  null
  
)

export default component.exports