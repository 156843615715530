<template>
  <div id="projectInfo">
    <ul>
      <li><strong>{{ $t('comp.projectinfo.domain.label') }}:</strong> {{ this.domainLabel + ' (FK:' + this.domainKey + ')' }}</li>
      <li><strong>{{ $t('comp.projectinfo.airelated.label') }}:</strong> {{ project?.aiRelated ? $t('yes.label') : $t('no.label') }}</li>
      <li><strong>{{ $t('comp.projectinfo.organization.label') }}:</strong> {{ project.organization.label }}</li>
      <li><strong>{{ $t('comp.projectinfo.unixGroupId.label') }}:</strong> {{ project.unixGroupId}}</li>
      <li><strong>{{ $t('comp.projectinfo.unixGroupName.label') }}:</strong> {{ project.unixGroupName }}</li>
      <li><strong>{{ $t('comp.projectinfo.state.label') }}:</strong> {{ $t(`states.${project.state}.label`) }}</li>
      <li><strong>{{ $t('comp.projectinfo.validFrom.label') }}:</strong> {{ formatDate(project.validFrom) }}</li>
      <li><strong>{{ $t('comp.projectinfo.validUntil.label') }}:</strong>
        {{ formatDate(project.validUntil) }}
        <b-icon-info-circle-fill v-if="project.willExpire && project.state === 'active'" variant="warning" class="ml-1"/>
        <b-icon-exclamation-circle-fill v-if="project.isExpired && project.state === 'active'" variant="danger"  class="ml-1"/>
      </li>
      <ul v-if="project.willExpire && project.state === 'active'">
        <li><strong>{{ $t('comp.projectinfo.note') }}:</strong> {{ $t('comp.projectinfo.willExpire.text') }}</li>
        <li><strong>{{ $t('comp.projectinfo.lastNotification.label') }}:</strong> {{ formatDate(project.lastNotification) }}</li>
      </ul>
      <ul v-if="project.isExpired && project.state === 'active'">
        <li><strong>{{ $t('comp.projectinfo.caution') }}:</strong> {{ $t('comp.projectinfo.gracePeriod.text') }}</li>
        <li><strong>{{ $t('comp.projectinfo.lastNotification.label') }}:</strong> {{ formatDate(project.lastNotification) }}</li>
      </ul>
      <li><strong>{{ $t('comp.projectinfo.accessibleFrom.label') }}:</strong> {{ formatDate(project.accessibleFrom) }}</li>
      <li><strong>{{ $t('comp.projectinfo.accessibleUntil.label') }}:</strong> {{ formatDate(project.accessibleUntil) }}</li>
    </ul>
    <ul class="mt-2">
      <li><strong>{{ $t('comp.projectinfo.pi.label') }}:</strong> {{ project.pi ? project.pi.label : $t('comp.projectinfo.nopi') }}</li>
      <li><strong>{{ $t('comp.projectinfo.advisors.label') }}:</strong>
        <span v-if="!project?.advisors || project.advisors.length === 0"> {{ $t('comp.projectinfo.noadvisors') }} </span>
        <span v-else-if="project.advisors.length === 1"> {{ project.advisors[0].label }} </span>
        <span v-else>
          <ul>
            <li v-for="advisor in project.advisors" :key="advisor.key">
              {{ advisor.label }}
            </li>
          </ul>
        </span>
      </li>
      <li><strong>{{ $t('comp.projectinfo.managers.label') }}:</strong>
        <span v-if="!project?.managers || project.managers.length === 0"> {{ $t('comp.projectinfo.nomanagers') }} </span>
        <span v-else-if="project.managers.length === 1"> {{ project.managers[0].label }} </span>
        <span v-else>
          <ul>
            <li v-for="manager in project.managers" :key="manager.key">
              {{ manager.label }}
            </li>
          </ul>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'ProjectInfo',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    domainLabel () {
      if (this?.project?.domain) {
        if (this.$i18n.locale === 'en') {
          return this.project.domain.label.en
        } else if (this.$i18n.locale === 'de') {
          return this.project.domain.label.de
        } else { // Fallback
          return this.project.domain.label.de
        }
      } else {
        return '-'
      }
    },
    domainKey () {
      if (this?.project?.domain?.key) {
        return this.project.domain.key
      } else {
        return '-'
      }
    }
  },
  methods: {
    formatDate (date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm') : '-'
    }
  }
}
</script>

<style scoped>
</style>
